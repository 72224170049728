import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from "./pages/Login.js";
import Home from "./pages/Home.js";
import UserEdit from "./pages/UserEdit.js";
import ChangePassword from './pages/ChangePassword.js'; 
import CreateLocation from './pages/CreateLocation.js';
import CreateTask from './pages/CreateTask.js';
import Devriyelerim from "./pages/Devriyelerim";
import DevriyeCheck from "./pages/DevriyeCheck";
import API from './services/api.js';

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  const fetchCurrentUser = async () => {
    try {
      const response = await API.get('/get_current_user', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCurrentUser(response.data);
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const onLoginSuccess = () => {
    fetchCurrentUser();
  };

  useEffect(() => {
    fetchCurrentUser();

    const preventPullToRefresh = (e) => {
      if (window.scrollY === 0 && e.touches && e.touches.length > 0) {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', preventPullToRefresh, { passive: false });

    return () => {
      document.removeEventListener('touchmove', preventPullToRefresh);
    };


  }, []);

  return (
    <Router>
      <div style={styles.header}>
        {currentUser ? (
          
          <p style={styles.userInfo}>
                   Bakırçay Üniversitesi<br></br>Çiğli Eğitim ve Araştırma Hastanesi
                   <br></br>Güvenlik Devriye
          </p>
          
          
        ) : (
          <p style={styles.userInfo}>
                   Bakırçay Üniversitesi<br></br>Çiğli Eğitim ve Araştırma Hastanesi
                   <br></br>Güvenlik Devriye
          </p>

        )}
      </div>
      <Routes>
        <Route path="/" element={<Login onLoginSuccess={onLoginSuccess} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/user-edit" element={<UserEdit />} />
        <Route path="/change-password" element={<ChangePassword />} /> 
        <Route path="/create-location" element={<CreateLocation />} />
        <Route path="/create-task" element={<CreateTask />} />
        <Route path="/devriyelerim" element={<Devriyelerim />} />
        <Route path="/devriyecheck" element={<DevriyeCheck />} />
        
      </Routes>
    </Router>
  );
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'center',  // Ortalamak için
    alignItems: 'center',       // Dikeyde ortalamak için
    padding: '10px 20px',
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #ddd',
  },
  userInfo: {
    fontSize: '20px',
    color: '#333',
    fontWeight: 'bold',         // Koyu yapmak için
    textAlign: 'center',        // Ortalamak için
    margin: 10,
  },
};

export default App;
