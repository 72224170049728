import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../services/api";

function Devriyelerim() {
  const [role, setRole] = useState(null);
  const [taskTracks, setTaskTracks] = useState([]);
  const [filteredTaskTracks, setFilteredTaskTracks] = useState([]);
  const [devriyeFilter, setDevriyeFilter] = useState("");
  const [lokasyonFilter, setLokasyonFilter] = useState("");
  const [userNameFilter, setUserNameFilter] = useState(""); // Kullanıcı adı için filtre
  const [userSurnameFilter, setUserSurnameFilter] = useState(""); // Kullanıcı soyadı için filtre
  const [dateFilter, setDateFilter] = useState(new Date().toISOString().split("T")[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();
  const taskTracksPerPage = 5;
  const indexOfLastTaskTrack = currentPage * taskTracksPerPage;
  const indexOfFirstTaskTrack = indexOfLastTaskTrack - taskTracksPerPage;
  const currentTaskTracks = filteredTaskTracks.slice(indexOfFirstTaskTrack, indexOfLastTaskTrack);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("Please login first");
          navigate("/");
          return;
        }

        const response = await API.get("/get_user_role", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setRole(response.data.role);
        fetchTaskTracks(response.data.role);
      } catch (error) {
        console.error("Error fetching role", error);
        alert("Session expired. Please log in again.");
        navigate("/");
      }
    };

    const fetchTaskTracks = async (userRole) => {
      try {
        const endpoint =
          userRole === "admin"
            ? `/get_all_task_tracks_with_names?date=${dateFilter}`
            : `/get_user_task_tracks_with_names?date=${dateFilter}`;
    
        const response = await API.get(endpoint, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        const taskTracks = response.data.task_tracks || [];
        setTaskTracks(taskTracks);
        setFilteredTaskTracks(taskTracks);
      } catch (error) {
        console.error("Error fetching task tracks", error);
        setErrorMessage("Devriyeleri getirirken bir hata oluştu.");
      }
    };

    fetchRole();
  }, [navigate, dateFilter]);

  useEffect(() => {
    let filteredTracks = taskTracks.filter(
      (taskTrack) =>
        taskTrack.task_name.toLowerCase().includes(devriyeFilter.toLowerCase()) &&
        taskTrack.location_name.toLowerCase().includes(lokasyonFilter.toLowerCase())
    );

    if (role === "admin") {
      filteredTracks = filteredTracks.filter(
        (taskTrack) =>
          taskTrack.user_name?.toLowerCase().includes(userNameFilter.toLowerCase()) &&
          taskTrack.user_surname?.toLowerCase().includes(userSurnameFilter.toLowerCase())
      );
    }

    setFilteredTaskTracks(filteredTracks);
  }, [devriyeFilter, lokasyonFilter, userNameFilter, userSurnameFilter, taskTracks, role]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    date.setHours(date.getHours());
    return date.toLocaleString('tr-TR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div style={styles.container}>
      <h2>{role === "admin" ? "Tüm Devriyeler" : "Devriyelerim"}</h2>

      {errorMessage && (
        <div style={styles.errorContainer}>
          <p>{errorMessage}</p>
        </div>
      )}

      {role === "admin" && (
        <div style={styles.filters}>
          <input
            style={styles.input}
            type="text"
            placeholder="Devriye Adı Ara..."
            value={devriyeFilter}
            onChange={(e) => setDevriyeFilter(e.target.value)}
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Lokasyon Adı Ara..."
            value={lokasyonFilter}
            onChange={(e) => setLokasyonFilter(e.target.value)}
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Kullanıcı Adı Ara..."
            value={userNameFilter}
            onChange={(e) => setUserNameFilter(e.target.value)}
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Kullanıcı Soyadı Ara..."
            value={userSurnameFilter}
            onChange={(e) => setUserSurnameFilter(e.target.value)}
          />
          <input
            style={styles.input}
            type="date"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          />
        </div>
      )}

      {filteredTaskTracks.length === 0 ? (
        <p>Devriye bulunamadı.</p>
      ) : (
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Devriye Adı</th>
              <th style={styles.th}>Lokasyon Adı</th>
              {role === "admin" && <th style={styles.th}>Kullanıcı Adı</th>}
              {role === "admin" && <th style={styles.th}>Kullanıcı Soyadı</th>}
              <th style={styles.thSmall}>Tarih</th>
              <th style={styles.thSmall}>Fotoğraf</th>
            </tr>
          </thead>
          <tbody>
            {currentTaskTracks.map((taskTrack) => (
              <tr key={taskTrack.id}>
                <td style={styles.td}>{taskTrack.task_name}</td>
                <td style={styles.td}>{taskTrack.location_name}</td>
                {role === "admin" && <td style={styles.td}>{taskTrack.user_name}</td>}
                {role === "admin" && <td style={styles.td}>{taskTrack.user_surname}</td>}
                <td style={styles.tdSmall}>{formatDate(taskTrack.timestamp)}</td>
                <td style={styles.tdSmall}>
                  {taskTrack.photo_url ? (
                    <img
                      src={taskTrack.photo_url}
                      alt="Devriye Fotoğrafı"
                      style={styles.thumbnail}
                    />
                  ) : (
                    "Fotoğraf yok"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div style={styles.pagination}>
        {Array.from(
          { length: Math.ceil(filteredTaskTracks.length / taskTracksPerPage) },
          (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              style={
                currentPage === index + 1
                  ? styles.activePageButton
                  : styles.pageButton
              }
            >
              {index + 1}
            </button>
          )
        )}
      </div>
      <button onClick={() => navigate("/home")} style={styles.button}>
        Geri Dön
      </button>
    </div>
  );
}


const styles = {
  container: {
    padding: "20px",
    maxWidth: "900px",
    margin: "auto",
    textAlign: "center",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  },
  errorContainer: {
    marginTop: "20px",
    padding: "15px",
    border: "1px solid #f44336",
    borderRadius: "10px",
    backgroundColor: "#ffebee",
    color: "#f44336",
    textAlign: "center",
  },
  filters: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    gap: "15px",
  },
  input: {
    padding: "12px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    width: "calc(20% - 10px)",
    boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
    outline: "none",
    backgroundColor: "#f9f9f9",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  th: {
    padding: "15px",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "nowrap",
    backgroundColor: "#f2f2f2",
    color: "#333",
  },
  td: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
    wordBreak: "break-word",
    backgroundColor: "#fafafa",
  },
  thSmall: {
    padding: "15px",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "nowrap",
    backgroundColor: "#f2f2f2",
    color: "#333",
    width: "15%",
  },
  tdSmall: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
    wordBreak: "break-word",
    backgroundColor: "#fafafa",
    width: "15%",
  },
  thumbnail: {
    width: "60px",
    height: "60px",
    objectFit: "cover",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: "20px",
  },
  pageButton: {
    padding: "10px 15px",
    fontSize: "14px",
    cursor: "pointer",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f2f2f2",
    transition: "background-color 0.3s, transform 0.3s",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  activePageButton: {
    padding: "10px 15px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#4CAF50",
    color: "white",
    borderRadius: "8px",
    border: "1px solid #ddd",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
    transform: "scale(1.05)",
  },
  button: {
    padding: "12px 20px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "10px",
    transition: "background-color 0.3s, transform 0.3s",
    width: "100%",
    maxWidth: "200px",
    textAlign: "center",
    margin: "30px 0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
};

export default Devriyelerim;
