import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../services/api";

function DevriyeCheck() {
  const [tasks, setTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [taskTracks, setTaskTracks] = useState([]);
  const [pendingLocations, setPendingLocations] = useState([]);
  const [dateFilter, setDateFilter] = useState(new Date().toISOString().split("T")[0]);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  // Görevleri (Tasks) getir
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await API.get("/get_tasks", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setTasks(response.data.tasks || []);
      } catch (error) {
        console.error("Error fetching tasks", error);
        setErrorMessage("Görevleri getirirken bir hata oluştu.");
      }
    };
    fetchTasks();
  }, []);

  // Task Tracks ve eksik lokasyonları getir
  useEffect(() => {
    const fetchTaskTracksAndPendingLocations = async () => {
      if (!selectedTaskId) return; // Seçili görev yoksa, sorgu yapma
      
      try {
        const response = await API.get(`/get_task_tracks_and_pending_locations?task_id=${selectedTaskId}&date=${dateFilter}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        setTaskTracks(response.data.task_tracks || []);
        setPendingLocations(response.data.pending_locations || []);
      } catch (error) {
        console.error("Error fetching task tracks and pending locations", error);
        setErrorMessage("Devriye kayıtlarını getirirken bir hata oluştu.");
      }
    };
    fetchTaskTracksAndPendingLocations();
  }, [selectedTaskId, dateFilter]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div style={styles.container}>
      <h2>Görev Seç ve Devriye Bilgilerini Gör</h2>

      {errorMessage && (
        <div style={styles.errorContainer}>
          <p>{errorMessage}</p>
        </div>
      )}

      <div style={styles.filters}>
        <select
          style={styles.select}
          value={selectedTaskId}
          onChange={(e) => setSelectedTaskId(e.target.value)}
        >
          <option value="">Bir Görev Seçiniz...</option>
          {tasks.map((task) => (
            <option key={task.id} value={task.id}>
              {task.name}
            </option>
          ))}
        </select>

        <input
          style={styles.input}
          type="date"
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
        />
      </div>

      {taskTracks.length === 0 ? (
        <p>Devriye bulunamadı.</p>
      ) : (
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Devriye Adı</th>
              <th style={styles.th}>Lokasyon Adı</th>
              <th style={styles.thSmall}>Tarih</th>
              <th style={styles.thSmall}>Fotoğraf</th>
            </tr>
          </thead>
          <tbody>
            {taskTracks.map((taskTrack) => (
              <tr key={taskTrack.id}>
                <td style={styles.td}>{taskTrack.task_name}</td>
                <td style={styles.td}>{taskTrack.location_name}</td>
                <td style={styles.tdSmall}>{formatDate(taskTrack.timestamp)}</td>
                <td style={styles.tdSmall}>
                  {taskTrack.photo_url ? (
                    <img
                      src={taskTrack.photo_url}
                      alt="Devriye Fotoğrafı"
                      style={styles.thumbnail}
                    />
                  ) : (
                    "Fotoğraf yok"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {pendingLocations.length > 0 && (
        <div style={styles.pendingLocationsContainer}>
          <h4>Gezilmeyen Lokasyonlar:</h4>
          <ul>
            {pendingLocations.map((location, index) => (
              <li key={index}>{location}</li>
            ))}
          </ul>
        </div>
      )}

      <button onClick={() => navigate("/home")} style={styles.button}>
        Geri Dön
      </button>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "900px",
    margin: "auto",
    textAlign: "center",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  },
  errorContainer: {
    marginTop: "20px",
    padding: "15px",
    border: "1px solid #f44336",
    borderRadius: "10px",
    backgroundColor: "#ffebee",
    color: "#f44336",
    textAlign: "center",
  },
  filters: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    gap: "15px",
  },
  input: {
    padding: "12px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    width: "45%",
    boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
    outline: "none",
    backgroundColor: "#f9f9f9",
  },
  select: {
    padding: "12px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    width: "45%",
    boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
    outline: "none",
    backgroundColor: "#f9f9f9",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  th: {
    padding: "15px",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "nowrap",
    backgroundColor: "#f2f2f2",
    color: "#333",
  },
  td: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
    wordBreak: "break-word",
    backgroundColor: "#fafafa",
  },
  thSmall: {
    padding: "15px",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "nowrap",
    backgroundColor: "#f2f2f2",
    color: "#333",
    width: "15%",
  },
  tdSmall: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
    wordBreak: "break-word",
    backgroundColor: "#fafafa",
    width: "15%",
  },
  thumbnail: {
    width: "60px",
    height: "60px",
    objectFit: "cover",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  pendingLocationsContainer: {
    textAlign: "left",
    marginTop: "20px",
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  button: {
    padding: "12px 20px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "10px",
    transition: "background-color 0.3s, transform 0.3s",
    width: "100%",
    maxWidth: "200px",
    textAlign: "center",
    margin: "30px 0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
};

export default DevriyeCheck;
